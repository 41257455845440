import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';

const Dashboard = lazy(() => import('../../container/dashboard'));
const Ecommerce = lazy(() => import('../../container/dashboard/Ecommerce'));
const Business = lazy(() => import('../../container/dashboard/Business'));
const Performance = lazy(() => import('../../container/dashboard/Performance'));
const CRM = lazy(() => import('../../container/dashboard/CRM'));
const Sales = lazy(() => import('../../container/dashboard/Sales'));
const Wizards = lazy(() => import('../../container/pages/wizards/Wizards'));
const DashboardRoutes = () => {

  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const {  isLoading, profile } = useSelector(state => {
    return {
      isLoading: state.crud.loading,
      profile: state.fb.profile,
    };
  });

  return (
    <Switch>
      {/* <Route exact path={path} component={Ecommerce} /> */}
      {/* <Route path={`${path}/social`} component={Dashboard} /> */}

  {profile.isActive ? <Route exact path={`${path}/`} component={Ecommerce} /> :  <Route path={path} component={Wizards} /> }  
   

      
      {/* <Route exact path={`${path}/business`} component={Business} />
      <Route exact path={`${path}/performance`} component={Performance} />
      <Route exact path={`${path}/crm`} component={CRM} />
      <Route exact path={`${path}/sales`} component={Sales} /> */}
    </Switch>
  );
};

export default DashboardRoutes;
